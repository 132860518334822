// eslint-disable-next-line no-unused-vars
/* eslint-disable no-unused-vars */

import { useNavigate, useLocation, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import {
  DashboardOutlined,
  BankOutlined,
  // TransactionOutlined,
  // MoneyCollectOutlined,
  // MonitorOutlined,
  // PieChartOutlined,
} from "@ant-design/icons";
import Header from "./header";
import DashboardPage from "../pages/dashboard";
import { RoleProtected } from "../routes/RoleProtected";
import { ROUTE_KEY } from "../common/const";
//transfer

const BillTransferPage = React.lazy(() => import("../pages/bill/transfer"));
const BillTransferMSBPage = React.lazy(() => import("../pages/bill/transfer/msb"));
const BillTransferVCBPage = React.lazy(() => import("../pages/bill/transfer/vcb"));
const BillTransferTECHPage = React.lazy(() => import("../pages/bill/transfer/tech"));
const BillTransferBIDVPage = React.lazy(() => import("../pages/bill/transfer/bidv"));
const BillTransferSacombankPage = React.lazy(() => import("../pages/bill/transfer/sacombank"));
const BillTransferVietinbankPage = React.lazy(() => import("../pages/bill/transfer/vietinbank"));
const BilltransferVPbankPage = React.lazy(() => import("../pages/bill/transfer/vpbank"));
const BillTransferMBbankPage = React.lazy(() => import("../pages/bill/transfer/mbbank"));
// const BillTransferMBbankPage = React.lazy(() => import("../pages/bill/transfer/mbbank"));
// const BillTransferMomoPage = React.lazy(() => import("../pages/bill/transfer/momo"));
const BillTransferMomoPagev2 = React.lazy(() => import("../pages/bill/transfer/momo/indexv2"));
const BillTransferAgribank = React.lazy(() => import("../pages/bill/transfer/agri"));
const BillTransferTpBank = React.lazy(() => import("../pages/bill/transfer/tpbank"));
const BillTransferACB = React.lazy(() => import("../pages/bill/transfer/acb"));
const BillTransferOCB = React.lazy(() => import("../pages/bill/transfer/ocb"));
//cons
//End transfer
//transaction

const BillTransactionPage = React.lazy(() => import("../pages/bill/transaction"));
const BillTransactionVCBPage = React.lazy(() => import("../pages/bill/transaction/vcb"));
const BillTransactionTCBPage = React.lazy(() => import("../pages/bill/transaction/tcb"));
const BillTransactionAcbPage = React.lazy(() => import("../pages/bill/transaction/acb"));
const BillTransactionMBBPage = React.lazy(() => import("../pages/bill/transaction/mbb"));
const BillTransactionTPBPage = React.lazy(() => import("../pages/bill/transaction/tpbank"));
const BillTransactionMomoPage = React.lazy(() => import("../pages/bill/transaction/momo"));

//End transaction
//balance

const BillBalancePage = React.lazy(() => import("../pages/bill/balance"));
const BillBalanceACBPage = React.lazy(() => import("../pages/bill/balance/acb"));
const BillBalanceBIDVPage = React.lazy(() => import("../pages/bill/balance/bidv"));
const BillBalanceAgribankPage = React.lazy(() => import("../pages/bill/balance/agribank"));
const BillBalanceVietComBankPage = React.lazy(() => import("../pages/bill/balance/vietcombank"));
const BillBalanceTpBank = React.lazy(() => import("../pages/bill/balance/tpbank"));
const BillBalanceVpBankPage = React.lazy(() => import("../pages/bill/balance/vpbank"));
const BillBalanceViettinPage = React.lazy(() => import("../pages/bill/balance/vietinbank"));

//End balance
//smsBanking
const SmsBankingPage = React.lazy(() => import("../pages/bill/smsbanking"));
const SmsBankVietComBankPage = React.lazy(() => import("../pages/bill/smsbanking/vietcombank"));
const SmsBankDongABank = React.lazy(() => import("../pages/bill/smsbanking/dongabank"));
const SmsBankBidv = React.lazy(() => import("../pages/bill/smsbanking/bidv"));
const SmsBankAgribankBankPage = React.lazy(() => import("../pages/bill/smsbanking/agribank"));
const SmsBankVietinBankPage = React.lazy(() => import("../pages/bill/smsbanking/vietinbank"));
const SmsBankACB = React.lazy(() => import("../pages/bill/smsbanking/acb"));
const SmsBankTpbank = React.lazy(() => import("../pages/bill/smsbanking/tpbank"));
const SmsBankMBBankPage = React.lazy(() => import("../pages/bill/smsbanking/mbbank"));
const SmsBankOCBBankPage = React.lazy(() => import("../pages/bill/smsbanking/ocbbank"));

//end smsBanking
//history
const HistoryPageNew88 = React.lazy(() => import("../pages/history/new88"));
const HistoryPageHi88 = React.lazy(() => import("../pages/history/hi88"));
const HistoryPageShBet = React.lazy(() => import("../pages/history/shbet"));
const HistoryPageJun88 = React.lazy(() => import("../pages/history/jun88/indexv2"));
const Bet789HistoryPage = React.lazy(() => import("../pages/history/789Bet"));
const HistoryPageF8bet = React.lazy(() => import("../pages/history/f8bet"));
//End history
//homeScreen
const HomeScreenNew88 = React.lazy(() => import("../pages/homeScreen/new88"));
const HomePage789Bet = React.lazy(() => import("../pages/homeScreen/789Bet"));
const HomePageJun88 = React.lazy(() => import("../pages/homeScreen/jun88"));
const HomeScreenHi88 = React.lazy(() => import("../pages/homeScreen/hi88"));

const HomeScreenF8bet = React.lazy(() => import("../pages/homeScreen/f8bet"));
const HomeScreenShBet = React.lazy(() => import("../pages/homeScreen/shbet"));

//End homeScreen
//gameScreen
const GamePageNew88 = React.lazy(() => import("../pages/gameScreen/new88"));
const GamePage789Bet = React.lazy(() => import("../pages/gameScreen/789Bet"));
const GamePageJun88 = React.lazy(() => import("../pages/gameScreen/jun88"));
const GamePageHi88 = React.lazy(() => import("../pages/gameScreen/hi88"));
const GamePageF8bet = React.lazy(() => import("../pages/gameScreen/f8bet"));
const GamePageShbet = React.lazy(() => import("../pages/gameScreen/shbet"));

//End gameScreen
// //widthDrawMoney
const DrawMoneyPageNew88 = React.lazy(() => import("../pages/widthDrawMoney/new88"));
const DrawMoneyPageHi88 = React.lazy(() => import("../pages/widthDrawMoney/hi88"));
const DrawMoneyPageShBet = React.lazy(() => import("../pages/widthDrawMoney/shbet"));
const DrawMoneyPageJun88 = React.lazy(() => import("../pages/widthDrawMoney/jun88"));
const WithdrawalRequest789 = React.lazy(() => import("../pages/widthDrawMoney/789Bet"));
const WithdrawalRequestF8bet = React.lazy(() => import("../pages/widthDrawMoney/f8bet"));
// //End widthDrawMoney
// widthdrawalHistory
const WidthdrawalHistoryJun88 = React.lazy(() => import("../pages/widthdrawalHistory/jun88"));

// End widthdrawalHistory
// Profile
const ProfilePage = React.lazy(() => import("../pages/auth/profile"));
const { Content, Sider } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const authProtectedRoutes = [
  {
    label: "Trang chủ",
    key: "dashboard",
    icon: <DashboardOutlined />,
    children: [
      {
        label: "Tổng Quan",
        key: "subdashboard",
        path: "/dashboard",
        component: <DashboardPage />,
        role: null,
      },
    ],
  },
  {
    label: "Tạo Bill", //Parant Menu
    key: "bill",
    icon: <BankOutlined />,
    children: [
      {
        label: "Bill chuyển khoản",
        key: "billTransfer",
        path: "/billTransfer",
        component: <BillTransferPage />,
      },
      {
        label: "Bill biến động",
        key: "billTransaction",
        path: "/billTransaction",
        component: <BillTransactionPage />,
      },
      {
        label: "Bill số dư",
        key: "billBalance",
        path: "/billBalance",
        component: <BillBalancePage />,
      },
      {
        label: "SMS Banking",
        key: "smsbanking",
        path: "/smsbanking",
        component: <SmsBankingPage />,
      },
    ],
  },
  {
    label: "Yêu Cầu Rút", //Parant Menu
    key: "withDrawMoney",
    children: [
      /*
      {
        label: "Jun88",
        key: "jun88",
        path: "/withDrawMoney/Jun88",
        role: ROUTE_KEY.JUN88,
        component: <DrawMoneyPageJun88 />,
      },
      */
      {
        label: "8KBET",
        key: "new88",
        path: "/withDrawMoney/8KBET",
        role: ROUTE_KEY.NEW88,
        component: <DrawMoneyPageNew88 />,
      }
      /*
      ,{
        label: "Hi88",
        key: "hi88",
        path: "/withDrawMoney/Hi88",
        role: ROUTE_KEY.HI88,
        component: <DrawMoneyPageHi88 />,
      },
      {
        label: "SHBET",
        key: "shbet",
        path: "/withDrawMoney/ShBet",
        role: ROUTE_KEY.SHBET,
        component: <DrawMoneyPageShBet />,
      },
      {
        label: "789BET",
        key: "789bet",
        path: "/withDrawMoney/789bet",
        role: ROUTE_KEY["789BET"],
        component: <WithdrawalRequest789 />,
      },
      {
        label: "F8BET",
        key: "f8bet",
        path: "/withDrawMoney/f8bet",
        role: ROUTE_KEY.F8BET,
        component: <WithdrawalRequestF8bet />,
      },
      */
    ],
  },
  {
    label: "Lịch sử Giao Dịch ", //Parant Menu
    key: "history",
    children: [
      /*
      {
        label: "Jun88",
        key: "jun88History",
        path: "/history/Jun88",
        role: ROUTE_KEY.JUN88,
        component: <HistoryPageJun88 />,
      },
      */
      {
        label: "8KBET",
        key: "new88History",
        path: "/history/8KBET",
        role: ROUTE_KEY.NEW88,
        component: <HistoryPageNew88 />,
      }
      /*
      ,{
        label: "Hi88",
        key: "Hi88History",
        path: "/history/Hi88",
        role: ROUTE_KEY.HI88,
        component: <HistoryPageHi88 />,
      },
      {
        label: "SHBET",
        key: "ShBetHistory",
        path: "/history/ShBet",
        role: ROUTE_KEY.SHBET,
        component: <HistoryPageShBet />,
      },
      {
        label: "789BET",
        key: "Bet789History",
        path: "/history/Bet789",
        role: ROUTE_KEY["789BET"],
        component: <Bet789HistoryPage />,
      },
      {
        label: "F8BET",
        key: "F8betHistory",
        path: "/history/F8bet",
        role: ROUTE_KEY.F8BET,
        component: <HistoryPageF8bet />,
      },
      */
    ],
  },
  {
    label: "Màn hình trang chủ ", //Parant Menu
    key: "homePage",

    children: [
      /*
      {
        label: "Jun88",
        key: "homepagejun88",
        path: "/homeScreen/Jun88",
        component: <HomePageJun88 />,
        role: ROUTE_KEY.JUN88,
      },
      {
        label: "789BET",
        key: "homepage789bet",
        path: "/homeScreen/789Bet",
        role: ROUTE_KEY["789BET"],
        component: <HomePage789Bet />,
      },
      */
      {
        label: "8KBET",
        key: "homeNew88",
        path: "/homeScreen/8KBET",
        role: ROUTE_KEY.NEW88,
        component: <HomeScreenNew88 />,
      },
      /*
      {
        label: "Hi88",
        key: "homeHi88",
        path: "/homeScreen/Hi88",
        role: ROUTE_KEY.HI88,
        component: <HomeScreenHi88 />,
      },
      {
        label: "F8BET",
        key: "homeF8bet",
        path: "/homeScreen/F8bet",
        role: ROUTE_KEY.F8BET,
        component: <HomeScreenF8bet />,
      },
      {
        label: "SHBET",
        key: "shbeth",
        path: "/homeScreen/ShBet",
        role: ROUTE_KEY.SHBET,
        component: <HomeScreenShBet />,
      },
      */
    ],
  },
  {
    label: "Màn hình game ", //Parant Menu
    key: "gamePage",
    children: [
      /*
      {
        label: "Jun88",
        key: "gameJun88",
        path: "/gameScreen/Jun88",
        role: ROUTE_KEY.JUN88,
        component: <GamePageJun88 />,
      },
      {
        label: "789BET",
        key: "game789Bet",
        path: "/gameScreen/789Bet",
        role: ROUTE_KEY["789BET"],
        component: <GamePage789Bet />,
      },
      */
      {
        label: "8KBET",
        key: "gameNew88",
        path: "/gameScreen/8KBET",
        role: ROUTE_KEY.NEW88,
        component: <GamePageNew88 />,
        //  icon: <PayCircleOutlined />,
      }
      /*
      ,{
        label: "Hi88",
        key: "gameHi88",
        path: "/gameScreen/Hi88",
        role: ROUTE_KEY.HI88,
        component: <GamePageHi88 />,
        //  icon: <PayCircleOutlined />,
      },
      {
        label: "F8BET",
        key: "gameF8bet",
        role: ROUTE_KEY.F8BET,
        path: "/gameScreen/F8bet",
        component: <GamePageF8bet />,
      },
      {
        label: "SHBET",
        key: "gameshbet",
        path: "/gameScreen/Shbet",
        role: ROUTE_KEY.SHBET,
        component: <GamePageShbet />,
        //  icon: <PayCircleOutlined />,
      },
      */
    ],
  },
  {
    label: "Lịch sử rút tiền",
    key: "historyWidthDraw",
    children: [
      {
        label: "Jun88",
        key: "historyWidthDrawJun88",
        path: "/withdrawal-history/Jun88",
        role: ROUTE_KEY.JUN88,
        component: <WidthdrawalHistoryJun88 />,
      },
    ],
  },
];

const routeAuthWithoutMenu = [
  //billTransaction
  {
    key: "billTransaction",
    path: "/billTransaction/vcb",
    component: <BillTransactionVCBPage />,
  },
  {
    key: "billTransaction",
    path: "/billTransaction/tcb",
    component: <BillTransactionTCBPage />,
  },
  {
    key: "billTransaction",
    path: "/billTransaction/acb",
    component: <BillTransactionAcbPage />,
  },
  {
    key: "billTransaction",
    path: "/billTransaction/mbb",
    component: <BillTransactionMBBPage />,
  },
  {
    key: "billTransaction",
    path: "/billTransaction/tpbank",
    component: <BillTransactionTPBPage />,
  },
  {
    key: "billTransaction",
    path: "/billTransaction/momo",
    component: <BillTransactionMomoPage />,
  },
  //billTransfer
  {
    key: "billTransfer",
    path: "/billTransfer/vcb",
    component: <BillTransferVCBPage />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/tech",
    component: <BillTransferTECHPage />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/bidv",
    component: <BillTransferBIDVPage />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/sacombank",
    component: <BillTransferSacombankPage />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/vietinbank",
    component: <BillTransferVietinbankPage />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/vpbank",
    component: <BilltransferVPbankPage />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/mbbank",
    component: <BillTransferMBbankPage />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/momo",
    component: <BillTransferMomoPagev2 />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/msb",
    component: <BillTransferMSBPage />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/agribank",
    component: <BillTransferAgribank />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/tpbank",
    component: <BillTransferTpBank />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/acb",
    component: <BillTransferACB />,
  }
  ,{
    key: "billTransfer",
    path: "/billTransfer/ocb",
    component: <BillTransferOCB />,
  },
  //billBalance
  {
    key: "billBalance",
    path: "/billBalance/acb",
    component: <BillBalanceACBPage />,
  },
  {
    key: "billBalance",
    path: "/billBalance/bidv",
    component: <BillBalanceBIDVPage />,
  },
  {
    key: "billBalance",
    path: "/billBalance/agribank",
    component: <BillBalanceAgribankPage />,
  },
  {
    key: "billBalance",
    path: "/billBalance/vcb",
    component: <BillBalanceVietComBankPage />,
  },
  {
    key: "billBalance",
    path: "/billBalance/tpbank",
    component: <BillBalanceTpBank />,
  },
  {
    key: "billBalance",
    path: "/billBalance/vpbank",
    component: <BillBalanceVpBankPage />,
  },
  {
    key: "billBalance",
    path: "/billBalance/vietinbank",
    component: <BillBalanceViettinPage />,
  },
  //smsbanking
  {
    key: "smsbanking",
    path: "/smsbanking/vcb",
    component: <SmsBankVietComBankPage />,
  },
  {
    key: "smsbanking",
    path: "/smsbanking/DongA",
    component: <SmsBankDongABank />,
  },
  {
    key: "smsbanking",
    path: "/smsbanking/bidv",
    component: <SmsBankBidv />,
  },
  {
    key: "smsbanking",
    path: "/smsbanking/agribank",
    component: <SmsBankAgribankBankPage />,
  },
  {
    key: "smsbanking",
    path: "/smsbanking/vietinbank",
    component: <SmsBankVietinBankPage />,
  },
  {
    key: "smsbanking",
    path: "/smsbanking/acb",
    component: <SmsBankACB />,
  },
  {
    key: "smsbanking",
    path: "/smsbanking/vpbank",
    component: <SmsBankTpbank />,
  },
  {
    key: "smsbanking",
    path: "/smsbanking/mbbank",
    component: <SmsBankMBBankPage />,
  },
  {
    key: "smsbanking",
    path: "/smsbanking/ocb",
    component: <SmsBankOCBBankPage />,
  },
  // {
  //   key: "profile",
  //   path: "/profile",
  //   component: <ProfilePage />,
  // },
  // profile
];

const itemsMenuSideBar = authProtectedRoutes.map((item, index) => {
  if (item.path === "/") return null;
  return getItem(
    item.label,
    item.key,
    item.icon,
    item.children?.map((itemChildren) => {
      return getItem(itemChildren.label, itemChildren.key, itemChildren.icon);
    })
  );
});

// submenu keys of first level
const rootSubmenuKeys = authProtectedRoutes.map((item, index) => {
  return item.key;
});

const AuthProtectedLayout = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [components, setComponents] = useState(null);
  const [openKeys, setOpenKeys] = useState(["dashboard"]);
  const [selectedKeys, setSelectedKeys] = useState("dashboard");
  const [role, setRole] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [roleName, setRoleName] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  useEffect(() => {
    //set navigation defaults for url "/"
    if (location.pathname === "/") return navigate("/dashboard");
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData && userData.listRole) {
      // const userRole = [];
      // userRole.push(userData.roleName);
      setRoleName(userData.listRole.toString().toUpperCase());
    }

    let _item = "";
    authProtectedRoutes.forEach((element) => {
      element.children &&
        element.children.forEach((elementChildren) => {
          if (elementChildren.path === location.pathname) {
            _item = elementChildren;
            return;
          }
        });
    });

    routeAuthWithoutMenu.forEach((element) => {
      if (element.path === location.pathname) {
        _item = element;
        return;
      }
    });

    if (!_item) return navigate("/404");

    setSelectedKeys(_item.key);
    handleSetOpenKeys(_item.key);
    setRole(_item.role || "user");
    setComponents(_item.component);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    let filteredMenuItems = [];
    // console.log(roleName);
    const keysToCheck = ["withDrawMoney", "gamePage", "homePage", "history", "historyWidthDraw"];
    if (roleName.includes("ADMIN")) {
      filteredMenuItems = itemsMenuSideBar;
    } else if (roleName.includes("BILL")) {
      filteredMenuItems = itemsMenuSideBar.map((item) => {
        if (!keysToCheck.includes(item.key)) {
          return item;
        }
        return filteredMenuItems;
      });
    } else {
      filteredMenuItems = itemsMenuSideBar.map((item) => {
        if (keysToCheck.includes(item.key)) {
          if (item.key === "historyWidthDraw") {
            if (roleName.includes("JUN88")) {
              return item;
            } else {
              return null;
            }
          } else {
           
            /*
            const filteredChildren = item.children.filter((child) => roleName.includes(child.label.toString().toUpperCase()));
          */
            const filteredChildren = item.children.filter(
              (child) => {
                if(roleName.includes("NEW88") && child.label.toString().toUpperCase() == "8KBET" ){
                  return true;
                }
              }

            );
            
            return {
              ...item,
              children: filteredChildren,
            };
          }
        }
        return item;
      });
    }
    setMenuItems(filteredMenuItems);
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsMenuSideBar, roleName]);
  const onClickItemMenu = (item) => {
    authProtectedRoutes.forEach((element) => {
      element.children &&
        element.children.forEach((elementChildren) => {
          if (elementChildren.key === item.key) {
            return navigate(elementChildren.path);
          }
        });
    });
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const handleSetOpenKeys = (expression) => {
    switch (expression) {
      case "billTransfer":
      case "billTransaction":
      case "billBalance":
      case "smsbanking":
        setOpenKeys(["bill"]);
        break;
      case "jun88":
      case "new88":
      case "hi88":
      case "789bet":
      case "f8bet":
      case "shbet":
        setOpenKeys(["withDrawMoney"]);
        break;
      case "jun88History":
      case "new88History":
      case "Bet789History":
      case "Hi88History":
      case "ShBetHistory":
      case "F8betHistory":
        setOpenKeys(["history"]);
        break;
      case "homepagejun88":
      case "homeNew88":
      case "homepage789bet":
      case "homeHi88":
      case "homeF8bet":
      case "shbeth":
        setOpenKeys(["homePage"]);
        break;
      case "gameJun88":
      case "gameNew88":
      case "game789Bet":
      case "gameHi88":
      case "gameF8bet":
      case "gameshbet":
        setOpenKeys(["gamePage"]);
        break;
      case "historyWidthDrawJun88":
        setOpenKeys(["historyWidthDraw"]);
        break;
      default:
        setOpenKeys(["dashboard"]);
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header />
      <Layout>
        <Sider
          width={250}
          className="site-layout-background"
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[selectedKeys]}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            items={menuItems}
            onClick={(item) => onClickItemMenu(item)}
          />
        </Sider>
        <Content
          className="site-layout-background"
          style={{
            padding: 12,
            margin: 0,
          }}
        >
          <RoleProtected role={role}>{components}</RoleProtected>
        </Content>
      </Layout>
    </Layout>
  );
};
export default AuthProtectedLayout;
