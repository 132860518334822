import { APIClient } from "./api_helper";
import * as url from "./url_helper";
import axios from "axios";

const api = new APIClient();
const loginUrl = process.env.REACT_APP_API_LOGIN_URL;

export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

export const login = async (req) => {
  // set url
  //axios.defaults.baseURL = process.env.REACT_APP_API_LOGIN_URL
  // version 1
  // const res = await axios.post(loginUrl + "/authenticate", req);
  // return res.value;
  // version 2
  const res = await axios.post("/users/login", req);
  return res;
};
export const verifyToken = (data) => {
  //axios.defaults.baseURL = process.env.REACT_APP_API_LOGIN_URL
  return api.post(loginUrl + "/validateToken", data);
};
export const getBanks = (data) => {
  return api.get(url.API_GET_BANKS, data);
};
export const getImage = (data) => {
  return api.get(url.API_GET_IMAGES, data);
};
export const getIcon = (data) => {
  return api.get(url.API_GET_ICONS, data);
};
export const getRandomIcon = (data) => {
  return api.get(url.API_GET_Notification, data);
};

export const getListImageByBillCodeAndBankCodeApin = async (data) => {
  let url = `/bills/${data.billCode}/banks/${data.bankCode}/images`;
  const res = await axios.get(url, {
    params: {
      osType: data.osType ? data.osType : "",
      type: data.type ? data.type : "",
      orderBy: data.orderBy,
    },
  });
  if (res.status === 200 || res.status === 204) {
    return res.data;
  }

  return Promise.reject(res);
};
export const getIconV2n = async (data) => {
  const res = await axios.get("/icons", {
    params: {
      osType: data.osType,
      iconType: data.iconType,
      iconLevel: data.iconLevel,
      iconColor: data.iconColor,
      orderBy: data.orderBy,
    },
  });
  if (res.status === 200 || res.status === 204) {
    return res.data;
  }
  return Promise.reject(res);
};
export const getListImageByBillCodeAndBankCodeApi = async ({
  billCode,
  bankCode,
}) => {
  const url = `/bills/${billCode}/banks/${bankCode}/images`;
  const res = await api.get(url, {
    orderBy: 1,
  });
  if (res.status === 200 || res.status === 204) {
    return res.data;
  }

  return Promise.reject(res);
};
export const getIconV2 = async ({ osType, iconType, iconLevel, iconColor }) => {
  const res = await api.get("/icons", {
    osType: osType,
    iconType: iconType,
    iconLevel: iconLevel,
    iconColor: iconColor,
  });
  return res.data;
};
export const getnotification = async (data) => {
  const url = "/notifications";
  const res = await api.get(url, {
    orderBy: 1,
  });
  return res.data;
};
// export const getTotalBill = async (data) => {
//   // /banks/:bankCode/beneficiaryBanks
//   const { userId = "" } = data;
//   const url = "https://dev.billmaker.net/api/billCreationHistories";
//   const res = await api.get(url, {
//     userId: userId,
//   });
//   // const res = await api.get(`https://dev.billmaker.net/api/billCreationHistories?userId=${userId}`);
//   return res;
// };
export const getIconNotificationV2 = async (params = {}) => {
  const { osType = 0, iconColor = 0 } = params;
  const res = await api.get("/icons", {
    osType: osType,
    iconType: 4,
    iconColor: iconColor,
  });
  return res.data;
};

export function cloneDeep(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export const randomListIcon = (number, list) => {
  let result = [];
  let listIcon = cloneDeep(list);
  let listIconLength = listIcon.length;
  if (number > listIconLength) {
    return listIcon;
  }
  for (let i = 0; i < number; i++) {
    let random = Math.floor(Math.random() * listIconLength);
    result.push(listIcon[random]);
    listIcon.splice(random, 1);
    listIconLength--;
  }
  return result;
};
export const getBankV2 = async (data) => {
  // /banks/:bankCode/beneficiaryBanks
  const { bankCode = "" } = data;
  const res = await api.get(`/banks/${bankCode}/beneficiaryBanks`);
  return res.data;
};

export const createBillCreationHistory = async (data) => {
  return await api.post("/billCreationHistories", data);
};

export const updatePassword = async (data) => {
  return await api.put("/users/updatePassword", data);
};
