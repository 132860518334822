import React, { useContext, useState } from "react";
import axios from "axios";
import { Button, Form, Input, Col, Row, notification, Typography } from "antd";
import { login } from "../../helpers/helper";
import img_login from "../../assets/images/img-signin.png";
import bg from "../../assets/images/cover-pattern.png";
import logo from "../../assets/images/logo-no-background.png";
import { FingerPrintContext } from "../../hooks/fingerPrintContext";

const { Title } = Typography;

export default function LoginPage() {
  document.title = "Đăng nhập";

  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const { fingerPrint, userData } = useContext(FingerPrintContext);

  const onFinish = async (values) => {
     const _req = {
       username: values.username,
       password: values.password,
       visitorId: '123456',
       ip: '0.0.0.1',
       countryCode: 'VN',
       device: 'unknown',
       browser: 'unknown',
     };
     
    /*
    const _req = {
      username: values.username,
      password: values.password,
      visitorId: fingerPrint,
      ip: userData?.ip,
      countryCode: userData?.countryCode,
      device: userData?.device,
      browser: userData?.browser,
    };
    */
    try {
      setLoading(true);
      const res = await login(_req);
      if (res.status !== 200) {
        return api["error"]({
          message: "Lỗi",
          description: res.message,
        });
      }
      localStorage.setItem("user", JSON.stringify(res?.data?.logedInUser));
      const token = res.data?.token;
      localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      return window.location.replace("/");
    } catch (e) {
      return api["error"]({
        message: "Lỗi",
        description: "Đăng nhập thất bại",
      });
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Row
      align="middle"
      justify="center"
      gutter={[16, 16]}
      className="login-pages"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <Row
        className="container-login"
        align="middle"
        justify="center"
        gutter={[16, 16]}
      >
        {contextHolder}
        <Col span={13} className="img-login">
          <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp" alt="" />
        </Col>
        <Col span={8} className="box-login">
          <Row>
            <Col span={24} justify="center" align="middle">
              <img src={logo} style={{ width: 300 }} alt="" />
            </Col>
          </Row>

          <Title
            style={{ display: "flex", justifyContent: "center" }}
            level={2}
          >
            Đăng nhập
          </Title>
          <Form
            name="basic"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Tên đăng nhập"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tên đăng nhập!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Mật khẩu"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập mật khẩu!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading ? true : false}
                style={{ width: "100%" }}
              >
                ĐĂNG NHẬP
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Row>
  );
}
