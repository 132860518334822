import React from "react";
import Route from "./routes";

import "./assets/styles/animation.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { FingerPrintProvider } from "./hooks/fingerPrintContext";

function App() {
  return (
    <>
      <FingerPrintProvider>
        <Route />
      </FingerPrintProvider>
    </>
  );
}

export default App;
