import { createContext, useEffect, useMemo, useState } from "react";
import fingerPrintJs from "@fingerprintjs/fingerprintjs";
import axios from "axios";

export const FingerPrintContext = createContext({
  fingerPrint: null,
  userData: null,
});

const getIpUrl = process.env.REACT_APP_API_GET_IP_URL;
export const FingerPrintProvider = ({ children }) => {
  const [fingerPrint, setFingerPrint] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    /*
    const getFingerPrint = async () => {
      const fp = await fingerPrintJs.load();
      const resultPromise = fp.get();
      const userDataPromise = axios.get(getIpUrl);
  
      try {
        
        const [result, userDataRes] = await Promise.all([
          resultPromise,
          userDataPromise,
        ]);
        
        setUserData(userDataRes.data);
        setFingerPrint(result.visitorId);
      } catch (error) {
        console.error("Error occurred:", error);
        // handle error, for instance setting userData to null
        setUserData(null);
      }
      
    };
    getFingerPrint();
    */
  }, []);

  const value = useMemo(
    () => ({ fingerPrint, userData }),
    [fingerPrint, userData]
  );

  return (
    <FingerPrintContext.Provider value={value}>
      {children}
    </FingerPrintContext.Provider>
  );
};
